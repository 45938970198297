import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Terms from "./views/Terms.vue";
import Privacy from "./views/Privacy.vue";

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'root',
      component: Home
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    // nếu không sẽ chuyển đến trang home
    {path: '*', redirect: '/'}
  ],
});

const publicPages = ['/', '/home', '/terms', '/privacy'];

router.beforeEach((to, from, next) => {
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in redirect to login page.
  if(authRequired && !loggedIn) {
    next({name: 'root', query: { redirect: to.path }});
  } else {
    next();
  }
});
