<template>
  <div>
    <div class="container" style="min-height: 400px">
      <!-- Begin Site Title ================================================== -->
      <div class="mainheading">
        <h1 class="sitetitle">Loopi</h1>
        <p class="lead">
          Ứng dụng học Tiếng Anh giao tiếp thường đàm thực dụng miễn phí
        </p>
        <div class="section-line"><p>&nbsp;</p></div>
      </div>
      <!-- End Site Title ================================================== -->

      <div class="row">
        <div class="col-md-2 col-xs-12"></div>
        <!-- Begin Post -->
        <div class="col-md-8 col-md-offset-2 col-xs-12">
          <div class="mainheading">
            <h1 class="posttitle">
              Loopi: Ứng dụng học Tiếng Anh giao tiếp thường đàm thực dụng miễn phí
            </h1>
          </div>
          <!-- Begin Featured Image -->
          <a href="https://apps.apple.com/app/id1540219034" target="_blank">
            <img class="featured-image img-fluid" src="../assets/img/loopi-banner.png" alt="loopi banner" />
          </a>
          <!-- End Featured Image -->
          <!-- Begin Post Content -->
          <div class="article-post">
            <p>
              <b>
                Đây là ứng dụng giành cho những ai miệt mài học Tiếng Anh mấy năm
                trời, hoặc dùng cả thanh xuân của mình để học Tiếng Anh mà vẫn ú
                ớ, ngập ngừng khi giao tiếp với người nước ngoài.
              </b>
            </p>
            <p>
              Lý do chính là do chúng ta chưa học Tiếng Anh giao tiếp theo hướng
              phản xạ. Tức là, ta luyện 2 kỹ năng nghe nói nhuyễn tới mức phản xạ
              vô điều kiện. Mà phản xạ vô điều kiện trong giao tiếp là gì? Là khi
              giao tiếp, chúng ta nghe và phản hồi lại một cách nhanh chóng, vô
              thức, không cần suy nghĩ quá nhiều, dùng chủ yếu là vùng não chức
              năng ngôn ngữ.
            </p>
            <p>
              Ngược lại là phản xạ có điều kiện, tức là khi giao
              tiếp, chúng ta dùng vùng não có chức năng phân tích logic để giao
              tiếp, chúng ta dùng rất nhiều nổ lực và năng lượng của vùng não
              logic này để cố gắng nhớ xem từ vựng Tiếng Anh tương ứng là gì, rồi
              ghép lại sao cho đúng ngữ pháp, phát âm ra sao... khiến cho việc
              giao tiếp chậm chạp, ngập ngừng, mất tự nhiên. Chính vì điều này, mà
              khi nghe và nói ngoại ngữ, chúng ta có cảm giác căng thẳng, nhức
              đầu, mệt mỏi... là do chúng ta dùng sai chức năng não bộ. Chúng ta
              cố gắng gồng mình, dùng vùng não logic vốn tốn nhiều năng lượng thay
              cho vùng não ngôn ngữ có chức năng phản xạ ngôn ngữ một cách vô
              thức.
            </p>
            <p>
              Để đạt được trạng thái phản xạ vô điều kiện trong giao tiếp, chúng
              ta cần trang bị cho bộ não của chúng ta những mẫu câu thường đàm
              trong giao tiếp hằng ngày, mà không cần quan tâm đến nó được viết
              như thế nào, ngữ pháp ra sao, chỉ cần lưu tâm nó có nghĩa gì và được
              phát âm như thế nào. Có nghĩa là khi cần diễn đạt ý nào thì nó bật
              ra ngay lập tức mà không cần suy nghĩ gì nhiều. Nó dựa trên nguyên
              lý học ngôn ngữ tự nhiên của 1 đứa trẻ. Bản thân chúng ta đều bắt
              đầu từ biết nghe, nghe mãi rồi mới biết nói, sau đó chúng ta mới
              biết đọc, biết viết. Điều đó lý giải vì sao những đứa trẻ bị điếc
              bẩm sinh thì cũng không biết nói luôn. Trong môi trường giáo dục,
              chúng ta học ngoại ngữ lại nặng về đọc viết, ít về nghe nói, cho nên
              chúng ta tốn bao nhiêu năm học Tiếng Anh mà kết quả cuối cùng giao
              tiếp vẫn không tốt là vì vậy.
            </p>
            <p>
              Ngay sau đây, Loopi mang đến một giải pháp giúp bạn học giao tiếp
              Tiếng Anh phản xạ vô điều kiện, mà không cần phải nổ lực gì cả, dễ
              lắm, chỉ cần siêng năng, kiên trì là được. Theo đại học Oxford, để
              đọc viết tốt Tiếng Anh bạn chỉ cần dùng khoảng 3000 từ vựng, thì để
              giao tiếp thường đàm hằng ngày chúng ta cũng chỉ cần dùng khoảng
              1000 câu đàm thoại ngắn.
            </p>
            <p>
              Vậy để học khoảng 1000 câu thường đàm một cách phản xạ mà lại không
              cần nổ lực là như thế nào. Loopi đã thiết kế cho bạn một chiến lược
              học như sau:
            </p>
            <ul>
              <li>
                <b>Mỗi ngày, bạn học 10 câu và học trong 3 buổi.</b>
              </li>
              <ul>
                <li>
                  <b>Buổi sáng</b>: Chúng ta nghe tốc độ chậm. Nghe đến đâu đọc
                  nhẩm (hoặc đọc to thành tiếng) nghĩa Tiếng Việt đến đó. Nhằm
                  liên kết việc nghe Tiếng Anh và nghĩa Tiếng Việt lại với nhau.
                  Mỗi câu được lặp lại 5 lần.
                </li>
                <li>
                  <b>Buổi trưa</b>: Chúng ta nghe tốc độ bình thường. Nghe đến đâu
                  đọc nhẩm (hoặc đọc to thành tiếng) nghĩa Tiếng Việt đến đó. Nhằm
                  liên kết việc nghe Tiếng Anh và nghĩa Tiếng Việt lại với nhau.
                  Mỗi câu được lặp lại 5 lần. Luyện khả năng nghe của tai.
                </li>
                <li>
                  <b>Buổi tối</b>: Chúng ta nghe tốc độ bình thường. Nghe đến đâu
                  đọc to Tiếng Anh đến đó. Mỗi câu được lặp lại 5 lần. Luyện khả
                  năng phát âm và ngữ điệu đàm thoại Tiếng Anh.
                </li>
              </ul>
              <li>
                <b>Cứ 5 ngày, chúng ta bỏ ra 1 ngày để ôn lại 5 ngày học trước đó</b>, 
                tức là 50 câu mà chúng ta vừa học trước đó. Mỗi câu lặp lại 2
                lần. Tương ứng với các ngày được tô màu sám trong ứng dụng Loopi.
                Vì bộ não của chúng ta ngoài cơ chế ghi nhớ, còn có cơ chế quên
                nữa. Cho nên việc bỏ ra 1 ngày để ôn lại là cần thiết nhé. Học
                được đã khổ mà để quên đi thì tiếc lắm.
              </li>
            </ul>
            <p>
              Mỗi buổi học, chúng ta chỉ học có 10 câu, mỗi câu lại ngắn, cho nên
              tổng thời lượng học chỉ khoảng từ 2 đến 3 phút, tương đương với nghe
              1 bài nhạc mà thôi. Lưu ý, khi học bạn nên thả lỏng và thoải mái,
              không cần phải tập trung quá mức cần thiết. Việc học mang tính lặp
              đi lặp lại cho quen tai quen miệng, rồi từ từ nó ngấm vào vùng não
              bộ ngôn ngữ lúc nào không hay. Đến lúc bạn vô tình xổ Tiếng Anh
              trong vô thức khi giao tiếp với người nước ngoài thì bạn sẽ ngạc
              nhiên cho mà xem. Bởi vậy, mới gọi là học giao tiếp theo kiểu phản
              xạ, không cần nổ lực gì hết.
            </p>
            <p>
              Tất cả các thông số như: 1 ngày học 10 câu, mỗi câu lặp lại 5 lần, 5
              ngày thì để 1 ngày ôn tập, đều có thể thay đổi ở menu "Cài đặt"
              tùy theo sở thích của mỗi người.
            </p>

            <h4>Một số hình ảnh về ứng dụng Loopi</h4>
            <b-carousel
              id="loopi-slide"
              v-model="slide"
              :interval="3000"
              controls
              indicators
              background="#ababab"
              img-width="210"
              img-height="400"
              style="text-shadow: 1px 1px 2px #000"
            >
              <b-carousel-slide>
                <template #img>
                  <div style="text-align: center">
                    <img
                      width="210"
                      height="400"
                      src="https://loopi.xyz/static/img/01-loopi-BaiHoc1.png"
                      alt="loopi BaiHoc"
                    />
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <div style="text-align: center">
                    <img
                      width="210"
                      height="400"
                      src="https://loopi.xyz/static/img/02-loopi-BaiHoc2.png"
                      alt="loopi BaiHoc"
                    />
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <div style="text-align: center">
                    <img
                      width="210"
                      height="400"
                      src="https://loopi.xyz/static/img/03-loopi-ChuDe1.png"
                      alt="loopi ChuDe"
                    />
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <div style="text-align: center">
                    <img
                      width="210"
                      height="400"
                      src="https://loopi.xyz/static/img/04-loopi-ChuDe2.png"
                      alt="loopi ChuDe"
                    />
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <div style="text-align: center">
                    <img
                      width="210"
                      height="400"
                      src="https://loopi.xyz/static/img/05-loopi-ChiTiet1.png"
                      alt="loopi ChiTiet"
                    />
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <div style="text-align: center">
                    <img
                      width="210"
                      height="400"
                      src="https://loopi.xyz/static/img/06-loopi-ChiTiet2.png"
                      alt="loopi ChiTiet"
                    />
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <div style="text-align: center">
                    <img
                      width="210"
                      height="400"
                      src="https://loopi.xyz/static/img/07-loopi-TimNhanh1.png"
                      alt="loopi TimNhanh"
                    />
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <div style="text-align: center">
                    <img
                      width="210"
                      height="400"
                      src="https://loopi.xyz/static/img/08-loopi-HuongDan.png"
                      alt="loopi HuongDan"
                    />
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <div style="text-align: center">
                    <img
                      width="210"
                      height="400"
                      src="https://loopi.xyz/static/img/09-loopi-CaiDat.png"
                      alt="loopi CaiDat"
                    />
                  </div>
                </template>
              </b-carousel-slide>
            </b-carousel>

            <p>&nbsp;</p>
            <p>
              Cuối cùng, Loopi là ứng dụng miễn phí, mong muốn giúp mọi người có
              thể học Tiếng Anh giao tiếp một cách thoải mái hơn, phản xạ hơn và
              ngày càng thành công trong cuộc sống. Chúc vui vẻ.
            </p>
            <p>VietNam, 21h48 25/10/2020.</p>
            <p><b>-- Loopi --</b></p>

            <p>&nbsp;</p>
            <hr />
            <h4 style="text-align: center;">Tải ứng dụng trên điện thoại</h4>
            <div class="row">
              <div class="col-md-6" style="margin-top: 10px; text-align: center;">
                <a href="https://play.google.com/store/apps/details?id=xyz.loopi" target="_blank">
                  <img width="200" src="../assets/img/playstore.png" alt="loopi play store"/>
                </a>
              </div>
              <div class="col-md-6" style="margin-top: 10px; text-align: center;">
                <a href="https://apps.apple.com/app/id1540219034" target="_blank">
                  <img width="200" src="../assets/img/appstore.png" alt="loopi app store"/>
                </a>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>

    <div class="graybg">
			<div class="container">
				<div class="section-title">
					<h2><span>Author</span>&nbsp;</h2>
				</div>
				<div class="row">
					<div class="col-lg-3 col-md-6">
						<div class="card user-card">
							<div class="card-block">
								<div class="user-image">
									<img src="../assets/img/ntc.jpg" class="img-radius" alt="Nghĩa Ticy">
								</div>
								<h6 class="f-w-600 m-t-10 m-b-10">Nghia Ticy</h6>
								<p style="color: brown;">Founder NTC Solutions</p>
								<hr>
								<p class="m-t-15 text-muted">Người nông dân mê công nghệ và thích mơ mộng viễn vông.</p>
								<hr>
								<div class="row justify-content-center user-social-link">
									<div class="col-auto"><a href="https://www.facebook.com/congnghia69" target="_blank" title="Facebook"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/></a></div>
									<div class="col-auto"><a href="https://twitter.com/congnghia0609" target="_blank" title="Twitter"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/></a></div>
									<div class="col-auto"><a href="https://github.com/congnghia0609" target="_blank" title="Github"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'github' }"/></a></div>
									<div class="col-auto"><a href="https://www.linkedin.com/in/tran-cong-nghia-99238533" target="_blank" title="LinkedIn"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin' }"/></a></div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="card user-card">
							<div class="card-block">
								<div class="user-image">
									<img src="../assets/img/loopi-avatar.png" class="img-radius" alt="Loopi">
								</div>
								<h6 class="f-w-600 m-t-10 m-b-10">Loopi</h6>
								<p style="color: brown;">A Product of NTC Solutions</p>
								<hr>
								<p class="m-t-15 text-muted">Loopi: Ứng dụng học Tiếng Anh giao tiếp thường đàm thực dụng miễn phí.</p>
								<hr>
								<div class="row justify-content-center user-social-link">
									<div class="col-auto"><a href="https://play.google.com/store/apps/details?id=xyz.loopi" target="_blank" title="Google Play"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'google-play' }"/></a></div>
									<div class="col-auto"><a href="https://apps.apple.com/app/id1540219034" target="_blank" title="App Store"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'app-store' }"/></a></div>
									<div class="col-auto"><a href="https://www.facebook.com/loopi.xyz" target="_blank" title="Facebook"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/></a></div>
                  <div class="col-auto"><a href="https://loopi.xyz/" target="_blank" title="Website Loopi"><font-awesome-icon icon="home"/></a></div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-lg-6 col-md-12">
						<div class="card user-card">
							<div class="card-block">
								<!-- This will show in medium and up -->
								<div class="d-none d-md-block">
									<div class="fb-page" data-href="https://www.facebook.com/loopi.xyz" data-tabs="timeline" data-width="488" data-height="386" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/loopi.xyz" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/loopi.xyz">Loopi</a></blockquote></div>
								</div>
								<!-- This will show only in below medium form factors -->
								<div class="d-block d-md-none">
									<div class="fb-page" data-href="https://www.facebook.com/loopi.xyz" data-tabs="timeline" data-width="380" data-height="368" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/loopi.xyz" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/loopi.xyz">Loopi</a></blockquote></div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>

      <div class="container">
        <div class="section-title">
          <h2><span>Other products</span>&nbsp;</h2>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="card user-card">
              <div class="card-block">
                <div class="user-image">
                  <img src="../assets/img/dosan-avatar.png" class="img-radius" alt="DoSan">
                </div>
                <h6 class="f-w-600 m-t-10 m-b-10">DoSan</h6>
                <p style="color: brown;">A Product of NTC Solutions</p>
                <hr>
                <p class="m-t-15 text-muted">DoSan: Ứng dụng học Tiếng Hàn giao tiếp thường đàm thực dụng miễn phí.</p>
                <hr>
                <div class="row justify-content-center user-social-link">
                  <div class="col-auto"><a href="https://play.google.com/store/apps/details?id=xyz.dosan" target="_blank" title="Google Play"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'google-play' }"/></a></div>
                  <div class="col-auto"><a href="https://apps.apple.com/app/id1551374256" target="_blank" title="App Store"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'app-store' }"/></a></div>
                  <div class="col-auto"><a href="https://www.facebook.com/dosan.xyz" target="_blank" title="Facebook"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/></a></div>
                  <div class="col-auto"><a href="https://dosan.xyz/" target="_blank" title="Website DoSan"><font-awesome-icon icon="home"/></a></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
						<div class="card user-card">
							<div class="card-block">
								<div class="user-image">
									<img src="../assets/img/kamezoko-avatar.png" class="img-radius" alt="KaMeZoKo">
								</div>
								<h6 class="f-w-600 m-t-10 m-b-10">KaMeZoKo</h6>
								<p style="color: brown;">A Product of NTC Solutions</p>
								<hr>
								<p class="m-t-15 text-muted">KaMeZoKo: Ứng dụng học Tiếng Nhật giao tiếp thường đàm thực dụng miễn phí.</p>
								<hr>
								<div class="row justify-content-center user-social-link">
									<div class="col-auto"><a href="https://play.google.com/store/apps/details?id=xyz.kamezoko" target="_blank" title="Google Play"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'google-play' }"/></a></div>
									<div class="col-auto"><a href="https://apps.apple.com/app/id1556989816" target="_blank" title="App Store"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'app-store' }"/></a></div>
									<div class="col-auto"><a href="https://www.facebook.com/kamezoko.xyz" target="_blank" title="Facebook"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/></a></div>
                  <div class="col-auto"><a href="https://kamezoko.xyz/" target="_blank" title="Website KaMeZoKo"><font-awesome-icon icon="home"/></a></div>
								</div>
							</div>
						</div>
					</div>

          <div class="col-lg-3 col-md-6">
						<div class="card user-card">
							<div class="card-block">
								<div class="user-image">
									<img src="../assets/img/cotuongvn-avatar.png" alt="CoTuongVN">
								</div>
								<h6 class="f-w-600 m-t-10 m-b-10">CoTuongVN</h6>
								<p style="color: brown;">A Product of NTC Solutions</p>
								<hr>
								<p class="m-t-15 text-muted">CoTuongVN: Kỳ đàn giao lưu kỳ nghệ của các kỳ thủ khắp nơi giang hồ.</p>
								<hr>
								<div class="row justify-content-center user-social-link">
									<div class="col-auto"><a href="https://play.google.com/store/apps/details?id=com.cotuongvn.cotuongvn" target="_blank" title="Google Play"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'google-play' }"/></a></div>
									<div class="col-auto"><a href="https://apps.apple.com/app/id1645890957" target="_blank" title="App Store"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'app-store' }"/></a></div>
									<div class="col-auto"><a href="https://www.facebook.com/groups/670722593912029" target="_blank" title="Facebook"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/></a></div>
                  <div class="col-auto"><a href="https://cotuongvn.com/" target="_blank" title="Website CoTuongVN"><font-awesome-icon icon="home"/></a></div>
								</div>
							</div>
						</div>
					</div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      slide: 0,
    };
  },
  mounted() {
    this.setupFB();
	},
	methods: {
		async setupFB() {
      // console.log("In setupFB");
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId      : '197535128528670',
          cookie     : true,
          xfbml      : true,
          version    : 'v15.0'
        });
        window.FB.AppEvents.logPageView();
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
	},
};
</script>

<style>
.card.user-card {
	border-top: none;
	-webkit-box-shadow: 0 0 1px 2px rgba(0,0,0,0.05), 0 -2px 1px -2px rgba(0,0,0,0.04), 0 0 0 -1px rgba(0,0,0,0.05);
	box-shadow: 0 0 1px 2px rgba(0,0,0,0.05), 0 -2px 1px -2px rgba(0,0,0,0.04), 0 0 0 -1px rgba(0,0,0,0.05);
	-webkit-transition: all 150ms linear;
	transition: all 150ms linear;
}
.card {
	border-radius: 5px;
	-webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
	box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
	border: none;
	margin-bottom: 30px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.card .card-header {
	background-color: transparent;
	border-bottom: none;
	padding: 25px;
}
.card .card-header h5 {
	margin-bottom: 0;
	color: #222;
	font-size: 14px;
	font-weight: 600;
	display: inline-block;
	margin-right: 10px;
	line-height: 1.4;
}
.card .card-header+.card-block, .card .card-header+.card-block-big {
	padding-top: 0;
}
.user-card .card-block {
	text-align: center;
}
.card .card-block {
	padding: 25px;
}
.user-card .card-block .user-image {
	position: relative;
	margin: 0 auto;
	display: inline-block;
	padding: 5px;
	width: 160px;
	height: 160px;
}
.user-card .card-block .user-image img {
	z-index: 20;
	position: absolute;
	top: 5px;
	left: 5px;
	width: 150px;
	height: 150px;
}
.img-radius {
	border-radius: 50%;
}
.f-w-600 {
	font-weight: 600;
}
.m-b-10 {
	margin-bottom: 10px;
}
.m-t-25 {
	margin-top: 25px;
}
.m-t-15 {
	margin-top: 15px;
}
.card .card-block p {
	line-height: 1.4;
}
.text-muted {
	color: #919aa3!important;
}
.user-card .card-block .activity-leval li.active {
	background-color: #2ed8b6;
}
.user-card .card-block .activity-leval li {
	display: inline-block;
	width: 15%;
	height: 4px;
	margin: 0 3px;
	background-color: #ccc;
}
.user-card .card-block .counter-block {
	color: #fff;
}
.bg-c-blue {
	background: linear-gradient(45deg,#4099ff,#73b4ff);
}
.bg-c-green {
	background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}
.bg-c-yellow {
	background: linear-gradient(45deg,#FFB64D,#ffcb80);
}
.bg-c-pink {
	background: linear-gradient(45deg,#FF5370,#ff869a);
}
.m-t-10 {
	margin-top: 10px;
}
.p-20 {
	padding: 20px;
}
.user-card .card-block .user-social-link svg {
	font-size: 25px;
}
.text-facebook {
	color: #3B5997;
}
.text-twitter {
	color: #42C0FB;
}
.text-dribbble {
	color: #EC4A89;
}
</style>
